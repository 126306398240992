$main-bg-color: #FFF;
$gray-color: #EFEFEF;
$gray-text: #A7A7A7;
$gray-blue: #DFF6FF;
$main-blue: #1363DF;
$secondary-blue: #47B5FF;
$dark-blue: #06283D;

body {
  background-image: url(assets/bgGray.svg);
  background-size: cover;
}

@media screen and (min-width: 992px) {
  .main-container {
    width: 80%;
  }
}

.main-container {
  width: 90%;
  margin: 0 auto;
  background: $main-bg-color;
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
}

.content-container {
  min-height: 75vh;
}

.tab-switch {
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
  padding-left: 1rem !important;
  padding-right: 1rem !important;

  .tab {
    list-style-type: none;
    margin: 0;
    padding: 1rem 2rem;
    border-bottom: 5px solid transparent;
    width: 100%;
    font-weight: bold;
    transition: all 0.3s ease-in-out;

    &.active {
      border-bottom: 5px solid $main-blue;
    }
  }

  .tab:hover {
    color: $main-blue;
    cursor: pointer;
    border-bottom: 5px solid $main-blue;
  }
}

.file {
  visibility: hidden;
  width: 100px;
}

.file-label {
  &:before {
    content: 'Choose File';
    display: block;
    background-color: #fff;
    border: 2px solid $main-blue;
    color: $main-blue;
    border-radius: 5px;
    padding: 10px 20px;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    text-align: center;
    font-size: 1rem;
    width: 150px;
  }

  &:hover:before, &:active:before {
    border-color: $main-blue;
    color: $main-blue;
  }
}

.nav-links {
  margin-bottom: 0;

  .nav-link {
    font-weight: bold;
    border-bottom: 2px solid transparent;

    &.active {
      color: $main-blue;
      border-bottom: 2px solid $main-blue;
    }

    &:hover {
      cursor: pointer;
      color: $main-blue;
    }
  }
}

.domain-input {
  position: relative;

  input {
    position: absolute;
  }

  button {
    all: unset;
    cursor: pointer;
    position: relative;
    top: 10px;
    right: -90%;

    &:hover {
      color: $main-blue;
    }
  }
}

.form-input {
  border: 1px solid $gray-color;
  padding: 0.5rem 1rem;
  width: 100%;
  outline: none;
}

.detection-rows {
  height: 1600px;
}

//loader
.loader-overlay {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.loader-spinner svg {
  width: 30px;
  height: 30px;
  color: $main-blue;
}

.loader-spinner .loader-horizontal{
  background: red;
  height: 200px;
}

//analysis results {
.results-container {
  padding: 1rem;

  h3 {
    margin-bottom: 1rem;
  }
}

.analysis-engine-card, .stat-analysis-card {
  background: white;
  border: 1px $main-blue solid;
  padding: 0.5rem;
}

.chip {
  border-radius: 10px;
  border: 1px solid $main-blue;
  padding: 0.25rem;
}

// utility
.cursor-pointer {
  cursor: pointer;
}

p {
  margin-bottom: 0;
}

.text-primary {
  color: $main-blue !important;
}

.text-capitalize {
  text-transform: capitalize;
}

.gap-1 {
  gap: 0.5rem;
}

.gap-2 {
  gap: 1rem;
}

.gap-3 {
  gap: 1.5rem;
}

.gap-4 {
  gap: 2rem;
}

.gap-5 {
  gap: 2.5rem;
}

.text-gray {
  color: $gray-text;
}

.break-word {
  word-break: break-all;
}

.bg-tab-gray {
  background-color: $gray-color;
}